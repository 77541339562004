import axios from 'axios';
import firebase from './firebase'; // Ensure this points to your Firebase config file

//const BASE_URL = 'http://127.0.0.1:8080';
const BASE_URL = 'https://api.mindfulread.app';

export const fetchUserData = async () => {
  const token = await firebase.auth().currentUser.getIdToken();
  const response = await axios.get(`${BASE_URL}/me?firebase-token=${token}`);
  return response.data;
};

export const fetchUserSettings = async () => {
    const token = await firebase.auth().currentUser.getIdToken();
    const response = await axios.get(`${BASE_URL}/settings?firebase-token=${token}`);
    return response.data;
  };

export const updateUserSettings = async (settings) => {
  const token = await firebase.auth().currentUser.getIdToken();
  await axios.post(`${BASE_URL}/settings?firebase-token=${token}`, settings);
};

export const updateUserName = async (name) => {
  const token = await firebase.auth().currentUser.getIdToken();
  await axios.post(`${BASE_URL}/me?firebase-token=${token}`, { name });
};

export const exchangeToken = async (token) => {
  const response = await axios.get(`${BASE_URL}/exchange_token?firebase-token=${token}`);
  return response.data;
};

export const listArticles = async (limit, last_id) => {
  const token = await firebase.auth().currentUser.getIdToken();
  const response = await axios.get(`${BASE_URL}/articles?firebase-token=${token}&limit=${limit}&last_id=${last_id}`);
  return response.data;
};

export const session = async() => {
  const token = await firebase.auth().currentUser.getIdToken();
  const response = await axios.get(`${BASE_URL}/session?firebase-token=${token}`);
  return response.data;
};


